import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { Customer, CurrentUser, TestSettings, SQACustomer, Admin } from '../globals/globals';
import { AppError } from '../_helpers/app-error';
import { NotFoundError } from '../_helpers/not-found-error';
import { WrongPasswordError } from '../_helpers/wrong-password-error';
import { EmailNotAvailableError } from '../_helpers/email-not-available-error';
import { FailedLoginError } from '../_helpers/failed-login-error';
import { InvalidEmailError } from '../_helpers/invalid-email-error';
import { UserMisMatchError } from '../_helpers/user-mismatch-error';
import { User } from '../globals/user';
import { CrossAuthError } from '../_helpers/cross-auth-error';

// interface User {
//   uid: string;
//   email: string;
//   photoURL?: string;
//   displayName?: string;
//   facility: string;
// }


@Injectable({ providedIn: 'root' })
export class AuthService {

  user: Observable<Admin>;
  // currentUser: User;
  private loggedInStatus = JSON.parse(localStorage.getItem('loggedIn') || 'false');
  private currentUser: any = {};

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {

    // this.currentUser = {
    //   uid: '',
    //   email: '',
    //   photoURL: '',
    //   doctorName: '',
    //   facility: '',
    // };

    //// Get auth data, then get firestore user document || null
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          Customer.currentFacilityID = user.uid;
          this.currentUser.uid = user.uid;
          this.currentUser.email = user.email;
          this.currentUser.photoURL = user.photoURL;
          // this.currentUser.uid = user.uid;
          // this.currentUser.email = user.email;
          // this.currentUser.photoURL = user.photoURL;
          // this.currentUser.doctorName = user.displayName;
          // this.currentUser.facility = user.facility;
          // console.log('customer ', Customer.currentFacilityID);
          return this.afs.doc<Admin>(`MESUsers/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );

  }

  setLoggedIn(value: boolean) {
    this.loggedInStatus = value;
    localStorage.setItem('loggedIn', value.toString());
  }

  get isLoggedIn() {
    return JSON.parse(localStorage.getItem('loggedIn') || this.loggedInStatus.toString());
  }

  get authenticated(): boolean {
    console.log('auth state', this.afAuth.authState);
    return this.afAuth.authState !== null;
  }

  get currentUserObservable(): any {
    return this.afAuth.auth;
  }

  private handleError(err: firebase.FirebaseError) {
    console.log(err);
    if (err.code === 'auth/user-not-found') {
      throw (new NotFoundError(err));
    }

    if (err.code === 'auth/wrong-password') {
      throw (new WrongPasswordError(err));
    }

    if (err.code === 'auth/email-already-in-use') {
      throw (new EmailNotAvailableError(err));
    }

    if (err.code === 'auth/too-many-requests') {
      throw (new FailedLoginError(err));
    }

    if (err.code === 'auth/invalid-email') {
      throw (new InvalidEmailError(err));
    }

    if (err.code === 'auth/user-mismatch') {
      throw (new UserMisMatchError(err));
    }

    throw (new AppError(err));
  }

  // async emailLogin(value) {
  //   console.log(value);

  //   if (value.rememberme === true) {
  //     // const credential = await firebase.auth().signInWithEmailAndPassword(value.username, value.password);
  //     await firebase.auth().signInWithEmailAndPassword(value.username, value.password).then(credential => {
  //       // console.log('logged in as', credential.user);
  //       this.setLoggedIn(true);
  //       // this.setUserLoggedIn(true);
  //       firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

  //       return this.retrieveUserData(credential.user);
  //     }).catch(this.handleError);
  //   } else {
  //     await firebase.auth().signInWithEmailAndPassword(value.username, value.password).then(credential => {
  //       // console.log('logged in as', credential.user);
  //       this.setLoggedIn(true);
  //       // this.setUserLoggedIn(true);
  //       firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
  //       return this.retrieveUserData(credential.user);
  //     }).catch(this.handleError);

  //   }

  // }

  async emailLogin(value) {
    // console.log(value);

    if (value.rememberme === true) {
      const credential = await firebase.auth().signInWithEmailAndPassword(value.username, value.password);
      const usr = await this.checkUser(credential.user);
          console.log(usr);
          if(usr) {
            this.setLoggedIn(true);
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
            return await this.retrieveUserData(credential.user);
          } else {
            console.log('no interface user!');
            // return new NotFoundError();
          }

    } else {
      const credential = await firebase.auth().signInWithEmailAndPassword(value.username, value.password);
        // console.log('logged in as', credential.user);
      const usr = await this.checkUser(credential.user)
          console.log(usr);
          if(usr) {
            this.setLoggedIn(true);
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
            return await this.retrieveUserData(credential.user);

          } else {
            console.log('no interface user!');
            // throw (new AppError({message: 'no interface user'}));
          }

    }

  }

  async signUpWithEmail(value) {
    await firebase.auth().createUserWithEmailAndPassword(value.email, value.password).then(credential => {
      console.log(credential.user);
      this.setLoggedIn(false);
      // let appUser: User = this.setNewUser(credential.user, value.username, value.facility);
      let currentAdmin: Admin = {
        uid: credential.user.uid,
        email: credential.user.email,
        firstName: value.firstName,
        lastName: value.lastName,
      };

      let u = this.updateUserData2(currentAdmin);
      console.log('updated admin ', u);
      return u;
    }).catch(this.handleError);
  }

  private setNewUser(firebaseUser, name, facilityName) {
    let newUser: User;
    newUser = {
      uid: firebaseUser.uid,
      email: firebaseUser.email,
      photoURL: '',
      doctorName: name,
      facility: facilityName,
      phone: '',
      website: '',
      country: '',
      city: '',
      zipCode: '',
      streetNo: '',
      testCredits: 0,
      designation: '',
      optional: '',
    };

    return newUser;
  }



  private updateUserData2(user: any) {
    // Sets user data to firestore on login
    console.log('user ', user);

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`MESUsers/${user.uid}`);

    userRef.set(user, { merge: true }).then(r => {
      console.log('set new admin', r);
      return r;
    }).catch(this.handleError);

  }

  // private retrieveUserData(user) {
  //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`MESUsers/${user.uid}`);

  //   userRef.get().toPromise().then((snapshot) => {
  //     if (snapshot.exists) {
  //       console.log('admin user exists ', snapshot);
  //       return userRef.get();
  //     } else {
  //       console.log('admin user doesnt exist! ');
  //       this.router.navigate(['/authentication/access-denied']);

  //       // throw new NotFoundError();
  //       return {};
  //     }
  //   });

  //   // let returnedUser = userRef.get();
  //   // console.log(returnedUser);
  //   // return returnedUser;

  // }

  
  private retrieveUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`MESUsers/${user.uid}`);
    let returnedUser = userRef.get();
    // console.log(returnedUser);
    return returnedUser;

  }

  
  private async checkUser(user) {
    return new Promise<any>((res, rej) => {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`MESUsers/${user.uid}`);
      let returnedUser = userRef.get();
      // console.log(returnedUser);
      returnedUser.toPromise().then(usr => {
        console.log('returnedUser exists', usr.exists);
        console.log('returnedUser', usr.data());
        if (usr.exists) {
          res(true);
        } else {
          rej(new CrossAuthError());
        }
      }).catch(err => {
        console.log('returnedUser err', err);
        rej(new CrossAuthError());
      });
    });
  }

  signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.setLoggedIn(false);
      this.currentUser = {};
      this.router.navigate(['/']);
    });
  }

  async resetPassword(email) {
    if (email !== null) {
      await firebase.auth().sendPasswordResetEmail(email).then((resp) => {

        return true;
      }).catch(this.handleError);
    }
  }

  public UploadProfilePicture(filepath: File) {
    // console.log('file to upload ', filepath);
    // Create a root reference
    var storageRef = firebase.storage().ref();

    // Create a reference to 'mountains.jpg'
    var ref = storageRef.child(`${CurrentUser.uid}/profileimg.jpg`);

    ref.put(filepath).then((snapshot) => {
      // console.log('Uploaded a blob or file!', snapshot);
      snapshot.ref.getDownloadURL().then((url) => {
        // console.log('download url', url);
        CurrentUser.photoURL = url;
        // console.log('global user', CurrentUser);
        this.updateUserData2(CurrentUser);
      });

    });

  }

  public GetProfilePicture() {

      let storageRef = firebase.storage().ref().child(`${CurrentUser.uid}/profileimg.jpg`);
      storageRef.getDownloadURL().then(url => {
        return url;
      });

  }

  public async AuthenticateUser(providedEmail, providedPassword) {
  
    var user = firebase.auth().currentUser;
    var credential = firebase.auth.EmailAuthProvider.credential(
      providedEmail,
      providedPassword
    );

    // Prompt the user to re-provide their sign-in credentials

    await user.reauthenticateWithCredential(credential).then(() => {
      // User re-authenticated.
      console.log("User re-authenticated");
      return true;
    }).catch(this.handleError);
  }
}
