import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth.guard';


const routes: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'distributers',
    //     loadChildren: () => import('./distributers/distributer.module').then(m => m.DistributerModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'notifications',
    //     loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path:'credits',
        loadChildren:()=> import('./test-credits/test-credits.module').then(m=>m.TestCreditsModule),
        canActivate:[AuthGuard]
    },

    {
        path: 'customers',
        loadChildren: () => import('./customers/customer.module').then(m => m.CustomerModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'support',
        loadChildren: () => import('./Support/support.module').then(m => m.SupportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'widget',
        loadChildren: () => import('./widget/widget.module').then(m => m.WidgetModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'data-analysis',
        loadChildren: () => import('./data-analysis/data-analysis.module').then(m => m.DataAnalysisModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'authentication',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    {
        path: 'registration-numbers',
        loadChildren: () => import('./registration-numbers/registration-numbers.module').then(m => m.RegistrationModule)
    },
    {
        path: '',
        redirectTo: 'authentication',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
